<div class="terms-container py-5">
    <div class="container">
      <h2 class="text-center mb-5">Terms and Conditions</h2>
  
      <div class="terms-content">
        <p><strong>1. Introduction</strong></p>
        <p>Welcome to <a routerLink="/">ExamDedo.com</a>. By participating in our daily challenge and submitting your answers, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you should not use our services or participate in any of the activities offered on this platform.</p>
  
        <p><strong>2. Eligibility</strong></p>
        <p>To participate in the daily question challenge, you must:</p>
        <ul>
          <li>Provide a valid email address for notifications and prize announcements.</li>
          <li>Be a resident of India.</li>
        </ul>
  
        <p><strong>Ineligible Participants:</strong></p>
        <ul>
          <li>Employees, contractors, or immediate family members of <a routerLink="/">ExamDedo.com</a> are not eligible to participate.</li>
          <li>Users found to be violating the platform's rules or engaging in fraudulent behavior will be disqualified from the contest.</li>
        </ul>
  
        <p><strong>3. How to Participate</strong></p>
        <p>Users can participate by answering the daily question correctly.</p>
        <ul>
          <li>Participation requires submitting your correct answer and a valid email address.</li>
          <li>Each participant can only submit one entry per day.</li>
        </ul>
  
        <p><strong>4. Lucky Winner Selection</strong></p>
        <p>After the daily question is answered, all correct responses are entered into a random draw to determine the winner.</p>
        <ul>
          <li>Only one winner will be chosen from all correct answers.</li>
          <li>The winner will be selected via a fair and random process. The draw will take place at midnight.</li>
        </ul>
  
        <p><strong>5. Winner Notification</strong></p>
        <p>The winner will be notified by email within 24 hours of the draw. It is the responsibility of the winner to ensure that the email address provided is valid and accessible. If the winner does not respond within 48 hours of receiving the notification, the prize may be forfeited.</p>
  
        <p><strong>6. Email Verification and Payment Eligibility</strong></p>
        <p><strong>Email Verification:</strong> The email address you provide during registration is crucial for receiving notifications and claiming your prize.</p>
        <p><strong>Incorrect or Mismatched Email:</strong> If the email address you provide does not match the one associated with your account, you will not be eligible to receive any winnings.</p>
        <p><strong>Payment Eligibility:</strong> If your email does not belong to you (e.g., it is found to be fraudulent, incorrect, or not valid), you will forfeit your eligibility for the prize.</p>
  
        <p><strong>7. Prize Details</strong></p>
        <p>The prize for the winner will be INR 10.</p>
        <p><strong>Payment of Prize:</strong> The prize will be issued to the winner’s verified email address. Payments will be processed via UPI/Bank Transfer.</p>
        
        <p><strong>8. Code of Conduct</strong></p>
        <p>Participants must behave respectfully and professionally on the platform. Any form of abuse, harassment, or unethical behavior will result in disqualification from the contest.</p>
        <p>Participants must not engage in cheating or attempting to manipulate the system.</p>
  
        <p><strong>9. Privacy and Data Protection</strong></p>
        <p>By participating in the contest, you consent to the collection and processing of your personal data (e.g., name, email address) in accordance with our <a href="#">Privacy Policy</a>. Your information will only be used for the purposes of the contest and prize distribution.</p>
  
        <p><strong>10. Limitations of Liability</strong></p>
        <p><strong><a routerLink="/">ExamDedo.com</a></strong> is not responsible for any technical issues that prevent participation or result in errors during the process. We are not liable for any indirect, consequential, or incidental damages arising from participation in this contest. We will not be held responsible for lost, delayed, or incorrect submissions due to technical difficulties or any issues beyond our control.</p>
  
        <p><strong>11. Changes to Terms and Conditions</strong></p>
        <p><strong><a routerLink="/">ExamDedo.com</a></strong> reserves the right to modify or update these Terms and Conditions at any time without prior notice. All participants will be notified of any significant changes. It is your responsibility to check these terms periodically for updates.</p>
  
        <p><strong>12. Termination</strong></p>
        <p><strong><a routerLink="/">ExamDedo.com</a></strong> reserves the right to cancel or suspend the contest at any time without prior notice due to unforeseen circumstances, technical issues, or any other reason at its discretion. Any violation of these Terms and Conditions will result in immediate disqualification from the contest and forfeiture of any winnings.</p>
  
        <p><strong>13. Dispute Resolution</strong></p>
        <p>Any disputes arising from participation in the contest will be resolved in accordance with the laws of India. In case of any disputes, the decision of <strong><a routerLink="/">ExamDedo.com</a></strong> will be final and binding.</p>
  
        <p><strong>14. Indemnity</strong></p>
        <p>By participating, you agree to indemnify and hold <strong><a routerLink="/">ExamDedo.com</a></strong>, its affiliates, and employees harmless from any claims, damages, or legal proceedings arising from your participation in the contest.</p>
  
    </div>
    </div>
  </div>
  