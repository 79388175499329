import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qday-term-conditions',
  templateUrl: './qday-term-conditions.component.html',
  styleUrls: ['./qday-term-conditions.component.css']
})
export class QdayTermConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
