<div class="bootstrap-wrapper py-5 bg-light">
  <div class="container">

    <!-- Question of the Day Section -->
    <div class="row justify-content-center mb-5">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="card shadow-lg border-0">
          <div class="card-header bg-primary text-white text-center py-3">
            <h3 class="mb-0">Question of the Day - Win Rs.10 Every Day!</h3>
          </div>
          <div class="card-body p-4">
            <form class="question-form" (ngSubmit)="formSubmit()">
              <!-- Question -->
              <div class="mb-4">
                <h5 class="fw-bold text-secondary">Today's Question:</h5>
                <p class="fs-5" [innerHTML]="question"></p>
              </div>

              <!-- Options -->
              <div class="mb-4">
                <h6 class="fw-bold text-secondary">Choose Your Answer:</h6>
                <div class="options" *ngFor="let option of options; let i = index">
                  <div class="form-check d-flex align-items-center">
                    <input 
                      class="form-check-input me-2" 
                      type="radio" 
                      [(ngModel)]="givenAnswer" 
                      [value]="option" 
                      name="options" 
                      id="option{{i}}">
                    <label class="form-check-label mb-0" [for]="'option' + i">
                      <span [innerHTML]="option"></span>
                    </label>
                  </div>
                </div>
              </div>
                            
              <!-- Email -->
              <div class="mb-4">
                <label for="email" class="form-label fw-bold">Your Email <span class="text-danger">*</span></label>
                <input 
                  type="email" 
                  id="email" 
                  name="email" 
                  [(ngModel)]="email" 
                  class="form-control form-control-lg" 
                  placeholder="Enter your email" 
                  required>
              </div>

              <!-- Terms -->
              <div class="text-start mb-4">
                <small class="text-muted">
                  By submitting, you agree to our 
                  <a routerLink="/qdaytermandconditions" target="_blank" class="text-primary text-decoration-none">Terms and Conditions*</a>.
                </small>
              </div>

              <!-- Submit Button -->
              <div class="text-center">
                <button type="submit" class="btn btn-primary btn-lg px-5">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Last Week Winners Section -->
    <div class="row justify-content-center mb-5">
      <div class="col-12 col-md-10">
        <div class="card shadow-lg border-0">
          <div class="card-header bg-dark text-white text-center py-3">
            <h4 class="mb-0">Last Week's Winners</h4>
          </div>
          <div class="card-body p-4">
            <table class="table table-bordered table-striped">
              <thead class="table-primary">
                <tr>
                  <th>Email</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let winner of winners; let i = index">
                  <td>{{ winner.email }}</td>
                  <td>{{ winner.date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


    <!-- Winner Highlight Section -->
    <div class="row justify-content-center mb-5">
      <div class="col-12 col-md-10 text-center">
        <div class="p-4 bg-white rounded shadow-lg border">
          <h4 class="text-primary mb-3">
            <i class="bi bi-award-fill me-2"></i>
            Win Rs.10 Every Day!
          </h4>
          <p class="text-secondary mb-4">
            Thank you for participating in our daily question challenge! While multiple participants may provide the correct answer, only one lucky winner will be highlighted each day. This ensures that everyone has an equal chance to win, and the process remains exciting for all involved. 
            <a routerLink="/qdaytermandconditions" target="_blank" class="text-primary text-decoration-none">Terms and Conditions*</a> apply.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
