
<mat-sidenav-container mat-app-background [ngClass]="isDarkTheme? 'dark-theme' : 'light-theme' ">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
        <button
          mat-icon-button
          (click)="sidenav.toggle()"
          fxShow="true"
          fxHide.gt-sm
        >
          <mat-icon>menu</mat-icon>
        </button>
        <a routerLink="/" mat-icon-button aria-label="Example icon-button with menu icon">
            <img src="../../../assets/logo.png" width="40px" height="40px" alt=""><b class="title">ExamDedo</b>
        </a>
        <span class="menu-spacer"></span>

        <div fxShow="true" class="hide-on-small" fxHide.lt-md>
            <!-- The following menu items will be hidden on both SM and XS screen sizes -->
            <a [routerLink]="'/'" mat-button>Home</a>
            <a [routerLink]="'/posts'" mat-button>Blog</a>
            <a [routerLink]="'/qoftheday'" mat-button>Question of the day</a>
        </div>
        <a *ngIf="!isLoggedIn" [routerLink]="'/login'" mat-button>Login</a>
        <a *ngIf="!isLoggedIn" [routerLink]="'/signup'" mat-button>Register</a>
        <a *ngIf="isLoggedIn && user" routerLink="/profile" mat-button>Hi {{fname}} </a>
        <a *ngIf="isLoggedIn" (click)="logout()" mat-button>Logout</a>

        
    </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container fxFlexFill>
        <mat-sidenav #sidenav>
          <mat-nav-list (click)="sidenav.toggle()">
            <!-- <a (click)="sidenav.toggle()" href="" mat-list-item>Close</a> -->
            <a [routerLink]="'/'" mat-list-item>Home</a>
            <a [routerLink]="'/posts'" mat-list-item>Blog</a>
            <a [routerLink]="'/qoftheday'" mat-list-item>Question of the day</a>
          </mat-nav-list>
        </mat-sidenav>
        <mat-card>
            <!-- Dynamic Content -->
            <router-outlet></router-outlet> 
        </mat-card>
    
        <!-- footer -->
        <app-footer></app-footer>
      </mat-sidenav-container>
