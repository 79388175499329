import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { QuestionService } from 'src/app/services/question.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.css']
})
export class CompetitionComponent implements OnInit {

  selectedOption: string | null = null;
  selectedQuestionIndex: number | null = null;
  //Local variable
  qid;
  questions;
  cq;// Current Question
  cqNumber = 0;
  exam = {};
  questinAnswerList = [];
  visitedQuestions = {};
  submittedQuestions = {};

  // Calculating marks
  marksGot = 0;
  correctAnswers = 0;
  attempted  = 0;
  isSubmit = false;
  isTimer: any;
  subject;
  grade;
  duration = 0;
  timeFinsihed = false;

  constructor(private _locationSt : LocationStrategy,private route: Router, private _route : ActivatedRoute,private _questionService: QuestionService) { }

  ngOnInit(): void {

    this.qid = this._route.snapshot.params.qid;
    let subscriptionStatus = localStorage.getItem("subscriptionStatus");
    if(localStorage.getItem("isPaymentSuccessfull") == "true" || subscriptionStatus === 'SUBSCRIBED_TRUE'){

      this.subject = JSON.parse(localStorage.getItem("subject")).name;
      this.duration = JSON.parse(localStorage.getItem("subject")).duration;
      this.grade = JSON.parse(localStorage.getItem("subject")).gradeId;

      this.loadQuestions();
      this.preventBackButton();

    } else {
      this.route.navigate(['/']);
    }
    
  }

  loadQuestions() {
    
    this._questionService.getCompetitionQuestionsOfQuizBySubjectId(this.qid).subscribe(
      (data:any)=>{
        //success
        this.questions = data.data;
        console.log("total questions", this.questions);
        this.cq = this.questions[0];
        this.cqNumber = 0;
        this.markVisited(0);
        // Timmer will start while questions is loading
        this.isTimer = this.duration ==  0 ? this.questions.length * 2 * 60 : this.duration * 60;

        // // add new key and value for user given answers
        this.questions.forEach((q)=>{
          this.questinAnswerList.push({
            "qid": q.questionId,
           // "question": q.question,
            "givenAnswer" : "N.A"
          });
        });

        //Start timer function here
        this.startTimmer();
      },
      (error)=>{
        //error
        Swal.fire("Error","Server error while loading questions","error");
      },
    );
  }
   //Prevent to back button
   preventBackButton(){
      
    history.pushState(null,null,location.href);
    this._locationSt.onPopState(()=>{
      history.pushState(null,null,location.href);
    });

  }

  startTimmer(){
    let t = window.setInterval(()=>{
      //code
      if(this.isTimer <=0){

        // After Completing time quiz will submitedd automatically without confirmation
        this.timeFinsihed = true;
        this.submitQuiz();
        clearInterval(t);
      }else{
        this.isTimer--;
      }
    },1000);
  }

  //time formated
  getFormatedTime(){
    let min = Math.floor(this.isTimer / 60);
    let sec = this.isTimer - min * 60;
    return `${min} Min : ${sec} Sec`;
    
  }

  // Print Page
  printPage(){
    window.print();
  }
  
  prevQuestion(){
    if(this.cqNumber > 0){
      this.cqNumber--;
      this.cq = this.questions[this.cqNumber];
      this.markVisited(this.cqNumber);
    } 
  }

  nextQuestion(){
    if(this.checkSelection()){
      this.setExamObject();
      this._questionService.saveEachQuestionAndAnswer(this.exam);
      if(this.cqNumber <= this.questions.length - 2){
        this.markSubmitted(this.cqNumber);
        this.cqNumber++;
        this.cq = this.questions[this.cqNumber];
        this.markVisited(this.cqNumber);
      }
    } else{
      Swal.fire({
        title: 'Please select atleast one option',
        showCancelButton: false,
        confirmButtonText:'OK',
        icon: 'warning',
      })
    }
  }

  goToQuestion(val: number){
    this.cqNumber = val;
    this.cq = this.questions[this.cqNumber];
    this.markVisited(val);
  }

  checkSelection() {
    if (this.cq.givenAnswer === undefined) {
      return false
    } else {
      return true;
    }
  }

  setExamObject(){
    let userDetails = JSON.parse(localStorage.getItem("user"));
    let userId = userDetails.id;
    var questionId = this.cq.questionId;

    var itemToUpdate = this.questinAnswerList.filter(function(item) {
      return item.qid === questionId;
    })

    // Update the "givenAnswer" property for each filtered item
    itemToUpdate.forEach(function(item) {
      item.givenAnswer = this.cq.givenAnswer == undefined ? "N.A" : this.cq.givenAnswer;
    }, this);
    
    this.exam = {
      "studentId" : userId,
      "subjectId":  this.qid,
      "studentAnswer": JSON.stringify(this.questinAnswerList)
    };
  }

  markVisited(questionIndex) {
    this.visitedQuestions[questionIndex] = true;
  }

  isVisited(questionIndex) {
    return this.visitedQuestions[questionIndex];
  }

  markSubmitted(questionIndex) {
    this.submittedQuestions[questionIndex] = true;
  }

  isSubmitted(questionIndex) {
    return this.submittedQuestions[questionIndex];
  }

  finishQuiz() {
    this._questionService.submitQuestionAndAnswer(this.exam).subscribe(
      (response: any)=>{
        localStorage.removeItem("exam");
        localStorage.removeItem("isPaymentSuccessfull");
        this.route.navigate(['generate-report/'+response.data]);
      },
      (error) => {
        console.log("Error ==> ", error);
      }
    )
  }
  
  // Submit Quiz and calculating
  submitQuiz(){
    this.setExamObject();
    this._questionService.saveEachQuestionAndAnswer(this.exam);
    if(this.timeFinsihed) {
      this.finishQuiz();
    } else {
      Swal.fire({
        title: 'Do you want submit quiz',
        showCancelButton: true,
        confirmButtonText:'Submit Quiz',
        icon: 'warning',
      }).then((e)=>{
        if(e.isConfirmed){
          // User manually submit quiz then ask confirmation before submited quiz
          this.finishQuiz();
        }
      })
    }
    
  }

}