import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-new-dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.css']
})
export class NewDashboardComponent implements OnInit {

  subjectLsit : string;
  subscriptionStatus: string;
  expirationDate: string;

  constructor(private homeService: HomeService, private loginService: LoginService) { }

  ngOnInit(): void {
    if(this.loginService.isLoggedIn()) {
      this.getSubscriptionStatus();
    }
    this.getAllExamCards();
  }

  getAllExamCards(){
    this.homeService.getAllSubjectsCard().subscribe(
      (data: any) => {
        this.subjectLsit = data.data;
      },
      (error)=> {

      }
    );
  }

  getSubscriptionStatus() {
    this.subscriptionStatus = localStorage.getItem("subscriptionStatus");
  }

}