
<div class="home-container">
    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-content">
        <h1>Welcome to Your Learning Platform</h1>
        <p>Your gateway to academic excellence and growth.</p>
        <button mat-raised-button color="accent" routerLink="/dashboard">Explore Dashboard</button>
      </div>
    </section>

    <!-- Subscription Section -->
    <section class="subscription">
      <div *ngIf="subscriptionStatus === 'SUBSCRIBED_TRUE'; else notSubscribedOrExpired">
        <h2>Thank You for Subscribing!</h2>
        <p>Thanks for the subscription! Your expiry date is <strong>{{ expirationDate | date: 'longDate' }}</strong>.</p>
      </div>
      <ng-template #notSubscribedOrExpired>
        <ng-container *ngIf="subscriptionStatus === 'NOT_SUBSCRIBED'; else expiredSubscription">
          <h2>Subscribe Now</h2>
          <p>Take a subscription to save your money and boost your preparation</p>
          <button mat-raised-button color="primary" [routerLink]="'/subscribe'">Subscribe</button>
        </ng-container>
        <ng-template #expiredSubscription>
          <h2>Subscription Expired</h2>
          <p>Subscription is expired. Please subscribe again!</p>
          <button mat-raised-button color="primary" [routerLink]="'/subscribe'">Renew Subscription</button>
        </ng-template>
      </ng-template>
    </section>



    <!-- Resource Section -->
    <section class="resources">
        <h2>Student Resources</h2>
        <div class="resource-list">
          <div class="resource-card" *ngFor="let resource of resources">
            <mat-icon>{{ resource.icon }}</mat-icon>
            <h3>{{ resource.title }}</h3>
            <a [href]="resource.link">Access Resource</a>
          </div>
        </div>
      </section>

    <!-- About Section -->
    <section class="about">
        <h2>About Us</h2>
        <p>
          At <a href="/">ExamDedo</a>, we're dedicated to revolutionizing the way students gauge their academic progress and enhance their learning experiences.
      We understand the importance of continuous evaluation and its impact on students' educational journeys. 
      Hence, we have crafted a user-friendly and accessible platform where students can evaluate their performance conveniently and effectively.    Our mission is to provide students with a comprehensive, reliable, and affordable platform for assessing their knowledge, identifying strengths and weaknesses, and ultimately improving their academic performance.
      We aim to empower students by offering them an opportunity to measure their understanding of various subjects and topics through a hassle-free online exam system.

        </p>
    </section>

    <!-- Features Section -->
    <section class="features">
      <h2>What We Offer</h2>
      <div class="feature-cards">
        <div class="feature-card" *ngFor="let feature of features">
          <mat-icon>{{ feature.icon }}</mat-icon>
          <h3>{{ feature.title }}</h3>
          <p>{{ feature.description }}</p>
        </div>
      </div>
    </section>

    <!-- Popular Courses Section -->
    <section class="popular-courses">
      <h2>Popular Courses</h2>
      <div class="course-list">
        <div class="course-card" *ngFor="let course of popularCourses">
          <h3>{{ course.name }}</h3>
          <p>{{ course.category }}</p>
          <a [href]="course.link">Take Test</a>
          <!-- <button mat-button color="primary">Enroll Now</button> -->
        </div>
      </div>
    </section>

    <!-- Student Testimonials Section -->
    <section class="testimonials">
      <h2>What Our Students Say</h2>
      <div class="testimonial-list">
        <div class="testimonial-card" *ngFor="let testimonial of testimonials">
          <p>"{{ testimonial.message }}"</p>
          <h4>- {{ testimonial.student }}</h4>
        </div>
      </div>
    </section>

    <!-- Upcoming Events Section -->
    <section class="events">
      <h2>Upcoming Events</h2>
      <div class="event-list">
        <div class="event-card" *ngFor="let event of events">
          <h3>{{ event.title }}</h3>
          <p>{{ event.date }}</p>
        </div>
      </div>
    </section>


    <!-- Quick Links Section -->
    <section class="quick-links">
        <h2>Quick Links</h2>
        <div class="link-list">
          <a *ngFor="let link of quickLinks" [href]="link.url">{{ link.label }}</a>
        </div>
      </section>

      <!-- Motivational Quotes Section -->
    <section class="quotes">
        <h2>Motivational Quote</h2>
        <blockquote>{{ randomQuote }}</blockquote>
        <p>- {{ quoteAuthor }}</p>
      </section>

      <!-- Achievements Section -->
      <section class="achievements">
        <h2>Recent Achievements</h2>
        <div class="achievement-list">
          <div class="achievement-card" *ngFor="let achievement of achievements">
            <h3>{{ achievement.title }}</h3>
            <p>{{ achievement.details }}</p>
          </div>
        </div>
      </section>

      <!-- Newsletter Section -->
      <section class="newsletter">
        <h2>Stay Updated</h2>
        <p>Subscribe to our newsletter for the latest updates.</p>
        <form (submit)="subscribeNewsletter(newsletterEmail)">
          <input type="email" placeholder="Enter your email" [(ngModel)]="newsletterEmail" name="email" required>
          <button mat-raised-button color="primary">Subscribe</button>
        </form>
      </section>

      <!-- Study Groups Section -->
    <section class="study-groups">
        <h2>Recommended Study Groups</h2>
        <div class="group-list">
          <div class="group-card" *ngFor="let group of studyGroups">
            <h3>{{ group.name }}</h3>
            <p>Members: {{ group.members }}</p>
            <button mat-button color="accent">Join Group</button>
          </div>
        </div>
      </section>

      <!-- Leaderboard Section -->
      <section class="leaderboard">
        <h2>Top Performers</h2>
        <div class="leader-list">
          <div class="leader-card" *ngFor="let leader of leaders">
            <h3>{{ leader.name }}</h3>
            <p>Score: {{ leader.score }}</p>
          </div>
        </div>
      </section>

    <!-- FAQ Section -->
    <section class="faq">
      <h2>Frequently Asked Questions</h2>
      <div class="faq-item" *ngFor="let faq of faqs">
        <h4>{{ faq.question }}</h4>
        <p>{{ faq.answer }}</p>
      </div>
    </section>
</div>