<div class="bootstrap-wrapper" *ngIf="!isSubmit">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <!-- Instrustions -->
                <mat-card>
                    <h3><b>Instrustions</b></h3>
                    <mat-card-content>
                        <ul>
                            <li>Please Do not refresh page otherwise question will be lost and new questions will suffles</li>
                            <li>Don't switch tab</li>
                            <li>Don't minimize current window</li>
                        </ul>
                    </mat-card-content>
                </mat-card>


            </div>
            <div class="col-md-6">
                <!-- Questions -->
                <div fxFlex="10%">
                    <ng-container *ngIf="questions">
                        <h1 class="mt20">
                            <!-- On Going Quiz <b>{{questions[0].quiz.title}}</b> -->
                            On Going Quiz of <b>{{subject}} - {{grade}}th</b> Standard
                        </h1>
                    </ng-container>
                    
                </div>
                <div fxFlex="90%">
                    <mat-card *ngIf="cq" class="mt20">
                        <mat-card-content>
                            <p style="min-height: 40px;"><b>Q:{{cqNumber+1}}) </b> <span [innerHTML]="cq.question"></span>
                            </p>
    
                            <mat-divider></mat-divider>
    
                            <div class="row mt20">
                                <div class="col-md-12 mt20">
                                    <input type="radio" [(ngModel)]="cq.givenAnswer" [value]="cq.op1" [name]="i" /><span [innerHTML]="cq.op1"></span>
                                </div>
                                <div class="col-md-12 mt20">
                                    <input type="radio" [(ngModel)]="cq.givenAnswer" [value]="cq.op2" [name]="i" /><span [innerHTML]="cq.op2"></span>
                                </div>
                                <div class="col-md-12 mt20">
                                    <input type="radio" [(ngModel)]="cq.givenAnswer" [value]="cq.op3" [name]="i" /><span [innerHTML]="cq.op3"></span>
                                </div>
                                <div class="col-md-12 mt20">
                                    <input type="radio" [(ngModel)]="cq.givenAnswer" [value]="cq.op4" [name]="i" /><span [innerHTML]="cq.op4"></span>
                                </div>
                            </div>
    
                        </mat-card-content>
                    </mat-card>

                    <!--Prevous, Next and Submit button start-->
                    <div class="container mt20">
                        <div class="row">
                            <div class="col-md-9">
                                <button class="btn btn-primary m20" (click)="prev()">Prev</button>
                                <button class="btn btn-primary m20" (click)="next()">Save & Next</button>
                            </div>
                            <div class="col-md-3 text-right">
                                <button class="btn btn-primary m20" (click)="submitAnswer()">Submit</button>
                            </div>
                        </div>
                    </div>
                    <!--Prevous, Next and Submit button End-->

                </div>
            </div>
           <div class="col-md-3" id="button-container">
                <mat-card>
                    <mat-card-content>
                        <h4 class="text-center">Remaining Time: {{ getFormatedTime() }} </h4>
                    </mat-card-content>
                </mat-card>

                <mat-card>
                    <mat-card-content class="button-container">
                      <button 
                        class="pannel_buttons" 
                        ng-attr-answered="false" 
                        ng-attr-reviewed="false" 
                        *ngFor="let q of questions; let i = index" 
                        (click)="getSelectedQuestion(i)">
                        {{i + 1}}
                      </button>
                    </mat-card-content>
                  </mat-card>
                  
            </div>

        </div>
    </div>
</div>

<!-- No data Available in this Quiz -->
<div class="col-md-12" *ngIf="questions.length==0; else elseStatement">
    <mat-card class="ml20 text-center">
        <mat-card-content>
            <h1>No data Available in this Quiz</h1>
        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button color="primary" [routerLink]="'/user-dashboard/0'"> Home</button>
        </mat-card-actions>
    </mat-card>
</div>

<!-- else block when data is Available in inside any quiz then execute only that section -->
<ng-template #elseStatement>
    <!-- Show the result -->
    <div class="bootstrap-wrapper" *ngIf=isSubmit>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>
                            Quiz Result
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="text-center">
                        <h1>Marks Obtained: {{marksGot}}</h1>
                        <h1>Correct Answers : {{correctAnswers}}</h1>
                        <h1>Question Attemped: {{attempted}}</h1>
                    </mat-card-content>

                    <!-- Buuton -->

                    <mat-card-actions class="text-center">
                        <button mat-raised-button color="primary" [routerLink]="'/user-dashboard/0'"> Home</button>
                        <button (click)="printPage()" mat-raised-button color="accent"> Print</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</ng-template>