import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { NavbarComponent } from './componets/navbar/navbar.component';
import { FooterComponent } from './componets/footer/footer.component';
import { SignupComponent } from './pages/signup/signup.component';
import { LoginComponent } from './pages/login/login.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import {  MatIconModule } from '@angular/material/icon';
import { AuthInterceptorProviders } from './services/auth.interceptor';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { UserDashboardComponent } from './pages/user/user-dashboard/user-dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { MatListModule } from '@angular/material/list';
import { SidebarComponent } from './pages/admin/sidebar/sidebar.component';
import { WelcomeComponent } from './pages/admin/welcome/welcome.component';
import { Error404Component } from './pages/errorpage/error404/error404.component';
import { ViewCategoriesComponent } from './pages/admin/view-categories/view-categories.component';
import { AddCategoryComponent } from './pages/admin/add-category/add-category.component';
import { ViewQuizzesComponent } from './pages/admin/view-quizzes/view-quizzes.component';
import { AddQuizComponent } from './pages/admin/add-quiz/add-quiz.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import { UpdateQuizComponent } from './pages/admin/update-quiz/update-quiz.component';
import { ViewQuizQuestionsComponent } from './pages/admin/view-quiz-questions/view-quiz-questions.component';
import { AddQuestionsComponent } from './pages/admin/add-questions/add-questions.component';
import { UpdateQuestionComponent } from './pages/admin/update-question/update-question.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SidebarComponent as UserSidebar } from './pages/user/sidebar/sidebar.component';
import { NgxChartsModule }from '@swimlane/ngx-charts';
import { LoadQuizComponent } from './pages/user/load-quiz/load-quiz.component';
import { InstructionsComponent } from './pages/user/instructions/instructions.component';
import { StartComponent } from './pages/user/start/start.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from "ngx-ui-loader";
import { UpdateCategoryComponent } from './pages/admin/update-category/update-category.component';
import { AboutQuizComponent } from './pages/user/about-quiz/about-quiz.component';
import { ReportGenerateComponent } from './pages/user/report-generate/report-generate.component';
import { OrderComponent } from './pages/user/payment/order/order.component';
import { ActivateAccountComponent } from './pages/signup/activate-account/activate-account.component';
import { CompetitionComponent } from './pages/user/competition/competition.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResetPasswordConfirmationComponent } from './pages/reset-password-confirmation/reset-password-confirmation.component';
import { QuestionOfTheDayComponent } from './pages/question-of-the-day/question-of-the-day.component';
import { CreatePostComponent } from './pages/post/create-post/create-post.component';
import { PublishedPostComponent } from './pages/post/published-post/published-post.component';
import { PostListComponent } from './pages/post/post-list/post-list.component';
import { OnThisDayComponent } from './pages/on-this-day/on-this-day.component';
import { GovtjobsComponent } from './pages/govtjobs/govtjobs.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { QdayTermConditionsComponent } from './pages/question-of-the-day/qday-term-conditions/qday-term-conditions.component';
import { NewHomeComponent } from './pages/new-home/new-home.component';
import { NewDashboardComponent } from './pages/new-dashboard/new-dashboard.component';


// import { MatTableModule } from '@angular/material/table';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    SignupComponent,
    LoginComponent,
    DashboardComponent,
    UserDashboardComponent,
    ProfileComponent,
    SidebarComponent,
    WelcomeComponent,
    Error404Component,
    ViewCategoriesComponent,
    AddCategoryComponent,
    ViewQuizzesComponent,
    AddQuizComponent,
    UpdateQuizComponent,
    ViewQuizQuestionsComponent,
    AddQuestionsComponent,
    UpdateQuestionComponent,
    UserSidebar,
    LoadQuizComponent,
    InstructionsComponent,
    StartComponent,
    UpdateCategoryComponent,
    AboutQuizComponent,
    ReportGenerateComponent,
    OrderComponent,
    ActivateAccountComponent,
    CompetitionComponent,
    ResetPasswordComponent,
    ResetPasswordConfirmationComponent,
    QuestionOfTheDayComponent,
    CreatePostComponent,
    PublishedPostComponent,
    PostListComponent,
    OnThisDayComponent,
    GovtjobsComponent,
    ContactusComponent,
    SubscriptionComponent,
    QdayTermConditionsComponent,
    NewHomeComponent,
    NewDashboardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSidenavModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    MatSelectModule,
    CKEditorModule,
    NgxChartsModule,
    MatProgressSpinnerModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground:true,
    }),
    // MatTableModule,
    
  ],
  providers: [AuthInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
