<footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <a target="_blank" href="https://www.facebook.com/profile.php?id=61553921074496"><i class="fa-brands fa-square-facebook"></i></a>
        </div>
        <div class="col-md-3 col-sm-6">
          <h4><a href="https://merchant.razorpay.com/policy/NAcwmbcOLUm9Xx/privacy" target="_blank">Privacy Policy</a></h4>
          <!-- Add privacy policy content -->
        </div>
        <div class="col-md-3 col-sm-6">
          <h4><a href="https://merchant.razorpay.com/policy/NAcwmbcOLUm9Xx/terms" target="_blank">Terms and Conditions</a></h4>
          <!-- Add terms and conditions content -->
        </div>
        <div class="col-md-3 col-sm-6">
          <h4><a href="https://merchant.razorpay.com/policy/NAcwmbcOLUm9Xx/refund" target="_blank">Cancellation and Refund</a></h4>
          <!-- Add cancellation and refund content -->
        </div>
        <div class="col-md-3 col-sm-6">
          <h4><a href="https://merchant.razorpay.com/policy/NAcwmbcOLUm9Xx/shipping" target="_blank">Shipping and Delivery</a></h4>
          <!-- Add shipping and delivery content -->
        </div>
        <div class="col-md-3 col-sm-6">
          <h4><a [routerLink]="'/contactus'" mat-list-item>Contact Us</a></h4>
          <!-- Add contact us content -->
        </div>
        <!-- <p>&copy; 2023 ExamDedo</p> -->
      </div>
    </div>
  </footer>
  