import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.css']
})
export class NewHomeComponent implements OnInit {

  subscriptionStatus: string;
  expirationDate: string;
  randomQuote = 'The best way to predict the future is to create it.';
  quoteAuthor = 'Abraham Lincoln';
  constructor(private subscriptionService: SubscriptionService, private loginService: LoginService) { }


  features = [
    { icon: 'school', title: 'Students', description: '1000+ Registered students.' },
    { icon: 'assignment', title: 'Assignments', description: 'Manage and track your tasks.' },
    { icon: 'event', title: 'Events', description: 'Stay updated with upcoming events.' },
  ];


  popularCourses = [
    { name: 'IIT-JEE Mains', category: 'Competitive Exams', link: '/user-dashboard/instructions/1008' },
    { name: 'CTET Paper I', category: 'Competitive Exams', link: '/user-dashboard/instructions/1017' },
    { name: 'GATE Mechanical', category: 'Competitive Exams', link: '/user-dashboard/instructions/1016' },
  ];


  testimonials = [
    { student: 'Mohan Gupta', message: 'This platform transformed my learning experience!' },
    { student: 'Aman Singh', message: 'The courses are interactive and easy to follow.' },
  ];


  events = [
    { title: 'Hackathon 2024', date: 'December 15, 2024' },
    { title: 'Science Fair', date: 'January 20, 2025' },
  ];


  resources = [
    { icon: 'library_books', title: 'Question of the Day', link: '/qoftheday' },
    { icon: 'computer', title: 'Mock Tests', link: '/dashboard' },
    { icon: 'help', title: 'Blog', link: '/posts' },
  ];


  faqs = [
    { question: 'How do I enroll in a course?', answer: 'Visit the courses section and click enroll.' },
    { question: 'Can I access past assignments?', answer: 'Yes, all submissions are saved in your dashboard.' },
  ];


  announcements = [
    { title: 'Holiday Notice', details: 'Campus will remain closed on December 25th.' },
    { title: 'Exam Results', details: 'Results for Semester 1 are now available.' },
  ];


  studyGroups = [
    { name: 'Physics Enthusiasts', members: 24 },
    { name: 'History Buffs', members: 18 },
  ];


  leaders = [
    { name: 'Ramesh', score: 980 },
    { name: 'Siddharth', score: 950 },
  ];


  achievements = [
    { title: 'Hackathon Winner', details: 'Won 1st place in the 2024 Hackathon.' },
    { title: 'Scholarship Award', details: 'Received the Excellence in Science Scholarship.' },
  ];


  quickLinks = [
    { label: 'Help Center', url: '/help' },
    { label: 'My Profile', url: '/profile' },
    { label: 'Settings', url: '/settings' },
  ];
  newsletterEmail: string = '';


  subscribeNewsletter(email: string) {
    console.log(`Subscribed: ${email}`);
    alert('Thank you for subscribing!');
  }


  ngOnInit(): void {
    this.subscriptionStatus = 'NOT_SUBSCRIBED';
    if(this.loginService.isLoggedIn()) {
      let user = this.loginService.getUser();
      this.getSubscriptionStatus(user.phoneno);
    }
  }


  getSubscriptionStatus(userId) {
    this.subscriptionService.getSubscriptionStatus(userId).subscribe(
      (resp: any) =>{
        if(resp && resp.data && null != resp.data){
          let str: string = `${resp.data}`;
          console.log("Subscription:", resp)
          if("NOT_SUBSCRIBED" == resp.data) {
            this.subscriptionStatus = "NOT_SUBSCRIBED";
          } else if("EXPIRED_SUBSCRIPTION" == resp.data) {
            this.subscriptionStatus = "EXPIRED_SUBSCRIPTION";
          } else if(str.includes("SUBSCRIBED_TRUE")){
            this.subscriptionStatus = "SUBSCRIBED_TRUE";
            this.expirationDate = str.split("#")[1];
          }
        }
        localStorage.setItem("subscriptionStatus", this.subscriptionStatus);
      },
      (error)=> {


      });
  }


}