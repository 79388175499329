<div>
    <h4>Contact Us</h4>



    <p>
        You may contact our team 24/7 support using the information below:<br />

        Merchant Legal entity name: ExamDedo<br>
        Developer's Address: Prateek Mishra <br/> Bhikhampur, Sewapuri Varanasi UTTAR PRADESH 221403<br>
        Telephone No: 9454609803<br>
        E-Mail ID: apnaexamdedo@gmail.com<br>
    </p>
</div>