<div class="container py-5" style="min-height: 450px;">
    <h2 class="text-center mb-4">Select Your Subscription Plan</h2>
  
    <!-- Dropdown to Select Plan -->
    <div class="form-group text-center">
      <label for="planSelect">Choose Plan:</label>
      <select id="planSelect" class="form-control w-50 mx-auto" (change)="onPlanSelect($event)">
        <option value="" disabled selected>Select a plan</option>
        <option *ngFor="let plan of plans" [value]="plan.id">{{ plan.name }}</option>
      </select>
    </div>
  
    <!-- Display the Price of the Selected Plan -->
    <div class="mt-4 text-center" *ngIf="selectedPlan">
      <h4>Price: ₹{{ selectedPlan.price}} / {{ selectedPlan.interval }}</h4>
    </div>
  
    <!-- Pay Button -->
    <div class="text-center mt-4">
      <button class="btn btn-primary" [disabled]="!selectedPlan" (click)="payNow()">Pay Now</button>
    </div>
  </div>
  