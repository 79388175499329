import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/services/login.service';
import { OrderService } from 'src/app/services/order.service';
declare var Razorpay: any;

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  selectedPlan: any = null;
  form: any = {}; 
  paymentId: string;
  error: string;
  user = null;

  constructor(private router: Router, private loginService: LoginService, private orderService: OrderService) { }

  options = {
    "key": "",
    "amount": "", 
    "name": "ExamDedo Portal",
    "description": "Online Exam Portal",
    "image": "",//"https://www.javachinna.com/wp-content/uploads/2020/02/android-chrome-512x512-1.png",
    "order_id":"",
    "handler": function (response){
        var event = new CustomEvent("payment.success", 
            {
                detail: response,
                bubbles: true,
                cancelable: true
            }
        );    
        window.dispatchEvent(event);
    }
    ,
    "prefill": {
    "name": "",
    "email": "",
    "contact": ""
    },
    "notes": {
    "address": "",
    "planType": ""
    },
    "theme": {
    "color": "#7799cc"
    }
    };

  ngOnInit(): void {
    this.user = this.loginService.getUser();
    if(this.user && "a" != this.user.status) {
      this.router.navigate(['account-activate']);
      return;
    }
  }

  // Define available subscription plans
  plans = [
    { id: 'quarterly', name: 'Quarterly Plan', price: 29, interval: 'Quartely' },
    { id: 'halfyearly', name: 'Half-Yearly Plan', price: 50, interval: 'Half Yearly' },  // Price in paisa for Razorpay
    { id: 'yearly', name: 'Yearly Plan', price: 99, interval: 'Yearly' }
  ];

  // Handle plan selection
  onPlanSelect(event: any) {
    const planId = event.target.value;
    this.selectedPlan = this.plans.find(plan => plan.id === planId);
  }

  // Pay Now function (Here you can integrate Razorpay)
  payNow() {
    if (this.selectedPlan) {
      console.log(`Processing payment for: ${this.selectedPlan.name}`);
      // Here is where you would call Razorpay integration or your backend payment method
      this.initiatePayment(this.selectedPlan);
    }
  }

  initiatePayment(plan:any){
    this.paymentId = ''; 
    this.error = ''; 
    this.form.name = this.user.name;
    this.form.email = this.user.email;
    this.form.amount = plan.price;
    this.form.phone = this.user.phoneno;
    this.form.isSubscriptionFlow = true;
    this.form.planType = plan.id;

    this.orderService.createOrder(this.form).subscribe(
    resp => {
        var data = resp.data;
        this.options.key = data.secretKey;
        this.options.order_id = data.razorpayOrderId;
        this.options.amount = data.applicationFee; //paise
        this.options.prefill.name = this.form.name;
        this.options.prefill.email = this.form.email;
        this.options.prefill.contact = this.form.phone;
        var rzp1 = new Razorpay(this.options);
        rzp1.open();
                   
        rzp1.on('payment.failed', function (response){    
            // Todo - store this information in the server
            console.log(response.error.code);    
            console.log(response.error.description);    
            console.log(response.error.source);    
            console.log(response.error.step);    
            console.log(response.error.reason);    
            console.log(response.error.metadata.order_id);    
            console.log(response.error.metadata.payment_id);
            this.error = response.error.reason;
        }
        );
    }
    ,
    err => {
        this.error = err.error.message;
    }
    );
  }

  @HostListener('window:payment.success', ['$event']) 
    onPaymentSuccess(event): void {
        console.log("event=>", event);
        event.detail["subscriptionFlow"] = true;
        this.orderService.updateOrder(event.detail).subscribe(
        data => {
            this.paymentId = data.message; 
            localStorage.setItem("subscriptionStatus", "SUBSCRIBED_TRUE");
            Swal.fire({
              title:'Subscription done, redirecting to homepage',
               showCancelButton:true,
               confirmButtonText: 'ok',
               icon: 'info',
            }).then((result)=>{
              this.router.navigate(['/']);
            });

                  
        }
        ,
        err => {
            this.error = err.error.message;
        }
        );
    }

}
