import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuizService } from 'src/app/services/quiz.service';
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit {

  // Local Variable
  qid
  quizessData
  constructor(private loginService: LoginService, private _route: ActivatedRoute, private _quizService: QuizService, private _router: Router) { }

  ngOnInit(): void {

    this.qid = this._route.snapshot.params.qid;
    console.log(this.qid);
    var user = this.loginService.getUser();
    if("a" != user.status) {
      this._router.navigate(['/account-activate']);
      return;
    }

    this._quizService.getSingleQuiz(this.qid).subscribe(
      (data:any)=>{
        //Success
        this.quizessData = data;
        console.log("vivek", this.quizessData);
        localStorage.setItem("subject", JSON.stringify(this.quizessData.data));
      },
      (error)=>{
        //error
        console.log("Error", error);
        Swal.fire("Error" , "Server Error while loading quiz...");
      }
    );

  }
  startQuiz(){
    let subscriptionStatus = localStorage.getItem("subscriptionStatus");
    if(subscriptionStatus != "SUBSCRIBED_TRUE") {
      Swal.fire({
        title:'Do You want to make the payment & start the quiz',
         showCancelButton:true,
         confirmButtonText: 'Start',
         denyButtonText: "Don't Start",
         icon: 'info',
      }).then((result)=>{
        if (result.isConfirmed){
          this._router.navigate(['/order/'+this.qid]);
          //this._router.navigate(['/start/'+this.qid]);
        }else if(result.isDenied){
          Swal.fire("Changes are not saved", '' , 'info');
        }
      });
    } else {
      Swal.fire({
        title:'Do You want to start the quiz',
         showCancelButton:true,
         confirmButtonText: 'Start',
         denyButtonText: "Don't Start",
         icon: 'info',
      }).then((result)=>{
        if (result.isConfirmed){
          if(this.quizessData.data.competitive) {
            this._router.navigate(['/start/competition/' + this.quizessData.data.id]);
          } else {
            this._router.navigate(['/start/'+this.qid]);
          }
        }else if(result.isDenied){
          Swal.fire("Changes are not saved", '' , 'info');
        }
      });
    }
    
  }
  

}
